import { useEffect, useRef, useState } from "react";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import TailwindForm from "../../../rjsf";
import Modal from "../../../layout/Modal";
import { useLoading } from "../../../context/LoadingContext";
import { QUERY_SYSTEM_SMARTCART_SETTINGS } from "../../../../graphql/queries";
import { MUTATION_SYSTEM_SMARTCART_SETTINGS } from "../../../../graphql/mutations";

const schema: RJSFSchema = {
    title: "Smart Cart Settings",
    type: "object",
    required: ["jingles", "scanners"],
    properties: {
        jingles: {
            title: "Jingles",
            $ref: "#/definitions/Jingles",
        },
        scanners: {
            title: "Scanners",
            type: "array",
            items: {
                $ref: "#/definitions/Scanner",
            },
        },
    },
    definitions: {
        BuzzerAction: {
            oneOf: [
                {
                    type: "object",
                    title: "Play",
                    required: ["action", "duration_millis", "frequency_hz"],
                    properties: {
                        action: {
                            title: "Action",
                            type: "string",
                            enum: ["play"],
                            default: "play",
                        },
                        duration_millis: {
                            title: "Duration (ms)",
                            type: "integer",
                            minimum: 0.0,
                        },
                        frequency_hz: {
                            title: "Frequency (Hz)",
                            type: "integer",
                            minimum: 0.0,
                        },
                    },
                },
                {
                    type: "object",
                    title: "Wait",
                    required: ["action", "duration_millis"],
                    properties: {
                        action: {
                            title: "Action",
                            type: "string",
                            enum: ["wait"],
                            default: "wait",
                        },
                        duration_millis: {
                            title: "Duration (ms)",
                            type: "integer",
                            minimum: 0.0,
                        },
                    },
                },
            ],
        },
        Jingles: {
            type: "object",
            required: ["acknowledged", "error", "rejected"],
            properties: {
                acknowledged: {
                    title: "Acknowledged",
                    type: "array",
                    items: {
                        $ref: "#/definitions/BuzzerAction",
                    },
                },
                error: {
                    title: "Error",
                    type: "array",
                    items: {
                        $ref: "#/definitions/BuzzerAction",
                    },
                },
                rejected: {
                    title: "Rejected",
                    type: "array",
                    items: {
                        $ref: "#/definitions/BuzzerAction",
                    },
                },
            },
        },
        PixelColour: {
            title: "Pixel Colour",
            type: "object",
            required: ["red", "green", "blue"],
            properties: {
                red: {
                    title: "Red",
                    type: "integer",
                    minimum: 0.0,
                },
                green: {
                    title: "Green",
                    type: "integer",
                    minimum: 0.0,
                },
                blue: {
                    title: "Blue",
                    type: "integer",
                    minimum: 0.0,
                },
            },
        },
        Scanner: {
            title: "Scanner",
            type: "object",
            required: ["colour", "scanner"],
            properties: {
                colour: {
                    $ref: "#/definitions/PixelColour",
                },
                scanner: {
                    $ref: "#/definitions/ScannerId",
                },
            },
        },
        ScannerId: {
            title: "Scanner Type",
            oneOf: [
                {
                    type: "object",
                    title: "Leaf Node",
                    required: ["type"],
                    properties: {
                        type: {
                            type: "string",
                            title: "Type",
                            enum: ["leaf_node"],
                            default: "leaf_node",
                        },
                        address: {
                            title: "Address",
                            type: ["string", "null"],
                        },
                    },
                },
                {
                    type: "object",
                    title: "External",
                    required: ["type"],
                    properties: {
                        type: {
                            type: "string",
                            title: "Type",
                            enum: ["external"],
                            default: "external",
                        },
                    },
                },
            ],
        },
    },
};
const uischema = {
    scanners: {
        items: {
            colour: {
                "ui:group": [{ fields: ["red", "green", "blue"] }],
            },
        },
    },
};

export default function ModalSmartCartSettings({ system, isOpen, setIsOpen }) {
    const formRef = useRef<any>(null);
    const client = useApolloClient();
    const { setLoading } = useLoading();

    const [formData, setFormData] = useState(null);

    const { loading, data } = useQuery(QUERY_SYSTEM_SMARTCART_SETTINGS, {
        variables: {
            macAddress: system.macAddress,
        },
        skip: !isOpen,
    });
    const [submitFormMutation] = useMutation(
        MUTATION_SYSTEM_SMARTCART_SETTINGS,
        {
            onCompleted: () => {
                client.resetStore();
            },
        }
    );

    useEffect(() => {
        if (
            data &&
            data.system &&
            data.system.smartcart &&
            data.system.smartcart.settings
        ) {
            setFormData(data.system.smartcart.settings);
        }
    }, [data]);

    useEffect(() => {
        if (loading && !data) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [loading, data]);

    if (!system) {
        return null;
    }

    const handleSubmit = (ev) => {
        setLoading(true);
        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
                settings: ev.formData,
            },
        })
            .then((response) => {
                setFormData(ev.formData);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    return (
        <Modal
            children={
                <>
                    <TailwindForm
                        schema={schema}
                        uiSchema={uischema}
                        formData={formData}
                        onSubmit={handleSubmit}
                        validator={validator}
                        noHtml5Validate
                        ref={formRef}
                        children={true}
                        showErrorList={false}
                        focusOnFirstError={true}
                    />
                    <div className="flex flex-row gap-2 justify-end border-t pt-5">
                        <button
                            className="button light"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button info"
                            type="button"
                            onClick={() => formRef.current.submit()}
                        >
                            Update
                        </button>
                    </div>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-2xl"
        />
    );
}
