import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ConfigureAisleForm from "./ConfigureAisleForm";
import SensorCalibration from "../CalibrationSensors";
import Modal from "../../../layout/Modal";

type CalibrationMainProps = {
    system: CoreSystem;
    site: CoreSite;
    selectedNode: CoreNode;
    selectNode: (node: CoreNode) => void;
    isModalOpen: boolean;
    closeModal: () => void;
    refetch: () => void;
};

type RunCommissioningToolBtnProps = {
    selectedNode: CoreNode;
    system: CoreSystem;
    site: CoreSite;
    selectNode: (node: CoreNode) => void;
    refetch: () => void;
};

type ViewStep = "configure" | "calibrate";

const RunCommissioningTool = ({
    selectedNode,
    selectNode,
    system,
    site,
    refetch,
    closeModal,
    isModalOpen,
}: CalibrationMainProps) => {
    const [viewStep, setViewStep] = useState<ViewStep>("configure");

    const aisleName = selectedNode.clientRef || selectedNode.address;

    const handleNext = () => {
        setViewStep("calibrate");
    };

    const handleBack = () => {
        setViewStep("configure");
    };

    const handleCloseModal = () => {
        closeModal();
        setViewStep("configure");
    };

    const noSensorsConnected = useMemo(() => {
        return selectedNode.mappedPeripherals.filter(
            (p) => p.status === "Online"
        ).length;
    }, [selectedNode.mappedPeripherals]);
    const noSensorsExpected = useMemo(() => {
        return (
            selectedNode.configNoColumns * selectedNode.configNoSensors +
            selectedNode.configNoColumnsOv * selectedNode.configNoSensorsOv
        );
    }, [
        selectedNode.configNoColumns,
        selectedNode.configNoSensors,
        selectedNode.configNoColumnsOv,
        selectedNode.configNoSensorsOv,
    ]);

    const [sensorsState, setSensorsState] = useState<SensorsState>(() => {
        return {
            sensorsExpected: noSensorsExpected,
            sensorsConnected: noSensorsConnected,
            valid: noSensorsExpected === noSensorsConnected,
        };
    });

    // useEffect(() => {
    //     setSensorsState({
    //         sensorsExpected: noSensorsExpected,
    //         sensorsConnected: noSensorsConnected,
    //         valid: noSensorsExpected === noSensorsConnected,
    //     });
    // }, [noSensorsExpected, noSensorsConnected]);

    return (
        <Modal
            title={
                viewStep === "configure"
                    ? `Configuration - Aisle controller ${selectedNode.address}`
                    : `Calibrate sensors for ${aisleName}`
            }
            children={
                <>
                    {viewStep === "configure" && (
                        <ConfigureAisleForm
                            closeModal={handleCloseModal}
                            site={site}
                            selectedNode={selectedNode}
                            selectNode={selectNode}
                            system={system}
                            handleNext={handleNext}
                            refetch={refetch}
                            sensorsState={sensorsState}
                            setSensorsState={setSensorsState}
                        />
                    )}
                    {viewStep === "calibrate" && (
                        <SensorCalibration
                            closeModal={handleCloseModal}
                            selectedNode={selectedNode}
                            system={system}
                            site={site}
                            refetch={refetch}
                            handleBack={handleBack}
                            sensorsState={sensorsState}
                        />
                    )}
                </>
            }
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            size={viewStep === "configure" ? "max-w-xl" : "max-w-5xl"}
        />
    );
};

export default function RunCommissioningToolBtn({
    selectedNode,
    system,
    site,
    selectNode,
    refetch,
}: RunCommissioningToolBtnProps) {
    const { t } = useTranslation();

    const [isModalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);

    if (!selectedNode || !system) {
        return null;
    }

    if (!system.maintenanceMode) {
        return null;
    }

    return (
        <>
            <button
                id="btn-aisle-open-config"
                className="button indigo"
                onClick={() => setModalOpen(true)}
            >
                {t("Run Commissioning Tool")}
            </button>

            {isModalOpen && (
                <RunCommissioningTool
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    selectedNode={selectedNode}
                    selectNode={selectNode}
                    system={system}
                    site={site}
                    refetch={refetch}
                />
            )}
        </>
    );
}
