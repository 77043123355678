import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { HiOutlineBell, HiOutlineChatAlt } from "react-icons/hi";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import DropdownAccounts from "./DrowdownAccounts";
import DropdownLanguages from "./DrowdownLanguages";
import UserHeader from "./User";
import DropdownSuperAdmin from "./DropdownSuperAdmin";
import EnvTag from "./Env";

export default function Header() {
    const { t } = useTranslation();

    return (
        <div className="flex bg-white  px-5 py-3 md:py-0 md:h-16 justify-between items-center">
            <div className="flex w-1/3 gap-5 ">
                {/* <Search /> */}
                {/* <Permission scopes={["admin"]}>
                    <Link
                        to="/projects"
                        className="text-gray-700"
                        title="Projects"
                    >
                        Projects
                    </Link>
                </Permission>
                <Permission scopes={["admin", "installer", "maintainer"]}>
                    <Link to="/sites" className="text-gray-700" title="Sites">
                        Sites
                    </Link>
                </Permission> */}
            </div>
            <div className="flex items-center gap-1 mr-2">
                <div className="text-neutral-500 text-sm">
                    <DropdownSuperAdmin />
                </div>

                <div className="text-neutral-500 text-sm">
                    <DropdownLanguages />
                </div>

                <div className="text-neutral-500 text-sm">
                    <DropdownAccounts />
                </div>

                <Popover className="relative hidden md:flex">
                    {({ open }) => (
                        <>
                            <Popover.Button
                                className={classNames(
                                    open && "bg-gray-100",
                                    "group inline-flex items-center rounded-sm p-1.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100"
                                )}
                            >
                                <HiOutlineChatAlt fontSize={24} />
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute right-0 z-0 mt-2.5 w-80">
                                    <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                                        <strong className="text-gray-700 font-medium">
                                            {t("Messages")}
                                        </strong>
                                        <div className="mt-2 py-1 text-sm">
                                            {t("No messages.")}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>

                <Popover className="relative hidden md:flex">
                    {({ open }) => (
                        <>
                            <Popover.Button
                                className={classNames(
                                    open && "bg-gray-100",
                                    "btn-notifications group inline-flex items-center rounded-sm p-1.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100"
                                )}
                            >
                                <HiOutlineBell fontSize={24} />
                            </Popover.Button>
                            {/* <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
                                    <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                                        <strong className="text-gray-700 font-medium">
                                            {t("Notifications")}
                                        </strong>
                                        <div className="mt-2 py-1 text-sm">
                                            {t("No notifications.")}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition> */}
                        </>
                    )}
                </Popover>

                <UserHeader />

                <EnvTag />
            </div>
        </div>
    );
}
