import { useEffect, useRef, useState } from "react";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import TailwindForm from "../../../rjsf";
import Modal from "../../../layout/Modal";
import { useLoading } from "../../../context/LoadingContext";
import { QUERY_SYSTEM_SMARTSTOW_SETTINGS } from "../../../../graphql/queries";
import { MUTATION_SYSTEM_SMARTSTOW_SETTINGS } from "../../../../graphql/mutations";

const schema: RJSFSchema = {
    title: "Smart Stow Settings",
    type: "object",
    required: [
        "AutoDisarmOnSuccess",
        "ErrorIndication",
        "InstalledBranchNodes",
        "OurCluster",
        "OurFirstAisle",
        "PixelCount",
        "RelativeErrorLocations",
        "SounderMapping",
        "SuccessIndication",
        "WalkwayStartsOdd",
    ],
    properties: {
        AutoDisarmOnSuccess: {
            type: "boolean",
            title: "Auto Disarm On Success",
            default: false,
        },
        OurCluster: {
            type: "integer",
            format: "uint8",
            minimum: 0,
            title: "Our Cluster",
        },
        OurFirstAisle: {
            type: "integer",
            format: "uint8",
            minimum: 0,
            title: "Our First Aisle",
        },
        PixelCount: {
            type: "integer",
            format: "uint8",
            minimum: 0,
            title: "Pixel Count",
        },
        SounderMapping: {
            type: "object",
            properties: {
                "1": {
                    type: "integer",
                    format: "uint8",
                    minimum: 0,
                    title: "Sounder 1",
                },
                "2": {
                    type: "integer",
                    format: "uint8",
                    minimum: 0,
                    title: "Sounder 2",
                },
                "3": {
                    type: "integer",
                    format: "uint8",
                    minimum: 0,
                    title: "Sounder 3",
                },
                "4": {
                    type: "integer",
                    format: "uint8",
                    minimum: 0,
                    title: "Sounder 4",
                },
                "5": {
                    type: "integer",
                    format: "uint8",
                    minimum: 0,
                    title: "Sounder 5",
                },
                "6": {
                    type: "integer",
                    title: "Sounder 6",
                    pattern: "^[0-9]{1}$",
                },
            },
            required: ["1", "2", "3", "4", "5", "6"],
        },
        WalkwayStartsOdd: {
            type: "boolean",
            default: false,
            title: "Walkway Starts Odd",
        },
        InstalledBranchNodes: {
            type: "array",
            items: {
                type: "integer",
                format: "uint8",
                minimum: 0,
            },
            title: "Installed Branch Nodes",
        },
        SuccessIndication: {
            $ref: "#/definitions/Indicator",
            title: "Success Indication",
        },
        ErrorIndication: {
            $ref: "#/definitions/Indicator",
            title: "Error Indication",
        },
        RelativeErrorLocations: {
            type: "array",
            items: {
                $ref: "#/definitions/RelativeErrorLocation",
            },
            title: "Relative Error Locations",
        },
    },
    definitions: {
        RGBColour: {
            title: "Colour",
            examples: ["#FFFF00", "#00FF00", "#FF0000", "#0000FF", "#000000"],
            type: "string",
            pattern: "^#[0-9A-Fa-f]{6}$",
            default: "#00FF00",
        },
        Indicator: {
            type: "object",
            required: [
                "Colour",
                "ColourDurationMs",
                "SounderDurationMs",
                "SounderDutyFrac",
                "SounderFreqHz",
            ],
            properties: {
                Colour: {
                    $ref: "#/definitions/RGBColour",
                },
                ColourDurationMs: {
                    type: "integer",
                    format: "uint16",
                    minimum: 0,
                    title: "Colour Duration (ms)",
                },
                SounderDurationMs: {
                    type: "integer",
                    format: "uint16",
                    minimum: 0,
                    title: "Sounder Duration (ms)",
                },
                SounderDutyFrac: {
                    type: "number",
                    format: "float",
                    title: "Sounder Duty Fraction",
                },
                SounderFreqHz: {
                    type: "integer",
                    format: "uint16",
                    minimum: 0,
                    title: "Sounder Frequency (Hz)",
                },
            },
        },
        RelativeErrorLocation: {
            type: "object",
            required: ["c", "opposite"],
            properties: {
                c: {
                    type: "integer",
                    format: "int16",
                    default: 0,
                    title: "Column",
                },
                r: {
                    type: ["integer", "null"],
                    format: "int16",
                    default: null,
                    title: "Row",
                },
                opposite: {
                    type: "boolean",
                    default: false,
                    title: "Opposite",
                },
            },
        },
    },
};
const uischema = {
    SuccessIndication: {
        "ui:group": [
            { fields: ["Colour", "ColourDurationMs"] },
            {
                fields: [
                    "SounderDurationMs",
                    "SounderDutyFrac",
                    "SounderFreqHz",
                ],
            },
        ],
        Colour: {
            "ui:widget": "color",
        },
    },
    ErrorIndication: {
        "ui:group": [
            { fields: ["Colour", "ColourDurationMs"] },
            {
                fields: [
                    "SounderDurationMs",
                    "SounderDutyFrac",
                    "SounderFreqHz",
                ],
            },
        ],
        Colour: {
            "ui:widget": "color",
        },
    },
    SounderMapping: {
        "ui:group": [{ fields: ["1", "2", "3", "4", "5", "6"] }],
    },
};

export default function ModalSmartStowSettings({ system, isOpen, setIsOpen }) {
    const formRef = useRef<any>(null);
    const client = useApolloClient();
    const { setLoading } = useLoading();

    const [formData, setFormData] = useState(null);

    const { loading, data } = useQuery(QUERY_SYSTEM_SMARTSTOW_SETTINGS, {
        variables: {
            macAddress: system.macAddress,
        },
        skip: !isOpen,
    });
    const [submitFormMutation] = useMutation(
        MUTATION_SYSTEM_SMARTSTOW_SETTINGS,
        {
            onCompleted: () => {
                client.resetStore();
            },
        }
    );

    useEffect(() => {
        if (
            data &&
            data.system &&
            data.system.smartstow &&
            data.system.smartstow.settings
        ) {
            setFormData(data.system.smartstow.settings);
        }
    }, [data]);

    useEffect(() => {
        if (loading && !data) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [loading, data]);

    if (!system) {
        return null;
    }

    const handleSubmit = (ev) => {
        setLoading(true);
        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
                settings: ev.formData,
            },
        })
            .then((response) => {
                setFormData(ev.formData);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    return (
        <Modal
            children={
                <>
                    <TailwindForm
                        schema={schema}
                        uiSchema={uischema}
                        formData={formData}
                        onSubmit={handleSubmit}
                        validator={validator}
                        noHtml5Validate
                        ref={formRef}
                        children={true}
                        showErrorList={false}
                        focusOnFirstError={true}
                    />
                    <div className="flex flex-row gap-2 justify-end border-t pt-5">
                        <button
                            className="button light"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button info"
                            type="button"
                            onClick={() => formRef.current.submit()}
                        >
                            Update
                        </button>
                    </div>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-2xl"
        />
    );
}
