import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";

import Modal from "../../../layout/Modal";
import { QUERY_SYSTEM_STATUS } from "../../../../graphql/queries";
import { useLoading } from "../../../context/LoadingContext";

export default function ModalStatus({ system, isOpen, setIsOpen }) {
    const { setLoading } = useLoading();

    const [skip, setSkip] = useState(true);
    const { data, refetch } = useQuery(QUERY_SYSTEM_STATUS, {
        variables: {
            macAddress: system.macAddress,
        },
        skip: skip,
        notifyOnNetworkStatusChange: true,
    });

    const buttonRef = useRef(null);
    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    });

    if (!system) {
        return null;
    }

    const handleSubmit = async () => {
        setLoading(true);
        setSkip(false);
        await refetch();
        setLoading(false);
    };

    const closeModal = () => setIsOpen(false);

    return (
        <Modal
            title="System - Status"
            children={
                <>
                    {data && data.system && data.system.liveStatus && (
                        <div className="bg-gray-100 rounded p-4 max-w-full overflow-auto">
                            <pre className="text-sm text-gray-800">
                                {JSON.stringify(
                                    data.system.liveStatus,
                                    null,
                                    2
                                )}
                            </pre>
                        </div>
                    )}
                    <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-5">
                        <button
                            className="button light"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button info"
                            type="button"
                            onClick={handleSubmit}
                            ref={buttonRef}
                        >
                            Execute
                        </button>
                    </div>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-xl"
        />
    );
}
